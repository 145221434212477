
<div mdbModal #frame="mdbModal" class="modal fade center" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 99999;">
  <div class="modal-dialog modal-notify modal-danger" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead" style="text-align: center;"> {{util.translate('Pay with Stripe')}} </p>

        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="frame.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>

      <!--Body-->
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center" *ngIf="!addCard">
          <a type="button" mdbBtn color="danger" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
            (click)="addCard = true" *ngIf="cards?.length"> {{util.translate('Add New Card')}}
          </a>
        </div>
        <hr *ngIf="!addCard">
        <div *ngIf="!addCard" class="cards_list">
          <div class="form-check" style="display: flex;flex-direction: row;align-items: center;"
            *ngFor="let card of cards;let i = index">
            <input type="radio" style="margin-right: 5px;" [(ngModel)]="token" class="form-check-input"
              [value]="card.id" [id]="card.id" name="materialExampleRadios" mdbInput
              [checked]="card.id === token ? true:false">
            <label class="form-check-label" style="margin-left: 5px;" [for]="card.id"> {{card.brand}}
              XXXX{{card.last4}} {{util.translate('Expiry')}} {{card.exp_month}} / {{card.exp_year}}
            </label>
          </div>
        </div>
        <div *ngIf="!cards?.length || addCard">
          <!-- <input type="email" placeholder="Email" [(ngModel)]="email" class="form-control inputItem"> -->
          <div class="md-form">
            <input id="cnum" style="height: auto !important;" [(ngModel)]="cnumber" class="form-control" type="tel"
              autocomplete="cc-number" ccNumber mdbInput>
            <label for="cnum"> {{util.translate('Card Number')}} </label>
          </div>
          <div class="md-form">
            <input id="cvc" style="height: auto !important;" [(ngModel)]="cvc" class="form-control" type="tel"
              autocomplete="off" ccCvc mdbInput>
            <label for="cvc">{{util.translate('CVC')}} </label>
          </div>
          <div class="md-form">
            <input id="date" style="height: auto !important;" [(ngModel)]="date" class="form-control" type="tel"
              autocomplete="cc-exp" ccExp mdbInput>
            <label for="date">{{util.translate('Expire Date')}} </label>
          </div>
          <div class="md-form">
            <input type="text" style="height: auto !important;" id="cname" class="form-control" [(ngModel)]="cname"
              mdbInput>
            <label for="cname"> {{util.translate('Card Holder Name')}} </label>
          </div>
          <div class="md-form">
            <input type="email" style="height: auto !important;" id="email" class="form-control" [(ngModel)]="email"
              mdbInput>
            <label for="email"> {{util.translate('Email')}} </label>
          </div>

        </div>
      </div>

      <!--Footer-->
      <div class="modal-footer justify-content-center" *ngIf="!addCard && cards.length">
        <a type="button" mdbBtn color="danger" class="waves-light" mdbWavesEffect
          (click)="payWithCard()">{{util.translate('Submit')}}
        </a>
      </div>

      <div class="modal-footer justify-content-center" *ngIf="addCard || !cards?.length">
        <a type="button" mdbBtn color="danger" class="waves-light" mdbWavesEffect
          (click)="addcard()">{{util.translate('Pay')}}
        </a>
        <a type="button" mdbBtn color="danger" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
          (click)="addCard = false;frame.hide()"> {{util.translate('Cancel')}} </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #addressFromMap="mdbModal" class="modal fade center" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 99999;">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content">

      <!--Body-->
      <div class="modal-body">
        <div #map id="map" class="map"></div>
        <div>
          <h3 class="title01">{{util.translate('Set patient details & location')}}</h3>
          <!-- <p>{{util.translate('Health Check AREA')}}</p> -->
          <div class="borders">
            <p class="address">
              <i class="fa fa-check-circle" aria-hidden="true"></i> 
              {{address.name}} - {{ (address.length>50)? (address | slice:0:50)+'..':(address) }}
            </p>
            <p class="changeBtn" mdbWavesEffect (click)="changeAddress()">{{util.translate('Change')}}</p>
          </div>
          <div *ngIf="addressSelected === true" class="row"> 
            <div class="md-form col-6">
              <label for="house"> {{util.translate('HOUSE / FLAT NO')}} </label>
              <input id="house" style="height: auto !important;" [(ngModel)]="house" class="form-control" type="text"
                mdbInput> 
            </div>
            <div class="md-form  col-6" >
              <label for="landmark"> {{util.translate('LANDMARK')}} </label>
              <input id="landmark" style="height: auto !important;" [(ngModel)]="landmark" class="form-control"
                type="text" mdbInput>
              
            </div>
            <div class="md-form col-6">
              <label for="pincode"> {{util.translate('Pincode')}} </label>
              <input id="pincode" style="height: auto !important;" [(ngModel)]="pincode" class="form-control"
                type="text" mdbInput>
              
            </div>
            <div class="md-form col-6">
              <label for="pincode"> {{util.translate('Address Type')}} </label>
              <select class="browser-default custom-select" [(ngModel)]="title" class="form-control form-select">
                <option value="home"> {{util.translate('HOME')}} </option>
                <option value="work"> {{util.translate('WORK')}} </option>
                <option value="other"> {{util.translate('OTHER')}} </option>
              </select>
              
            </div>
 
            <div class="md-form col-6">
              <div style="text-align: left;" class="ng-star-inserted"> Patient Name </div>
              <input type="text" placeholder="Patient Name" [(ngModel)]="patient_name" class="form-control" >
              
            </div>
            <div class="md-form col-6">
                <div style="text-align: left;" class="ng-star-inserted"> Mobile Number</div>
                <input type="Mobile" placeholder="Mobile Number" [(ngModel)]="patient_mobile" class="form-control" >
                
            </div> 
            <div class="md-form col-6">
              <div style="text-align: left;" class="ng-star-inserted"> Email id </div>
              <input type="email" placeholder="Email id" [(ngModel)]="patient_email" class="form-control" >
              
            </div>
            <div class="md-form col-6">
                <div style="text-align: left;" class="ng-star-inserted"> Date of birth </div>
                <input type="date" placeholder="Date of birth*" [(ngModel)]="patient_dob"   class="form-control" max="{{sdate}}">
                
              </div> 
            <div class="md-form col-6">
                      <div style="text-align: left;" class="ng-star-inserted"> Select Gender </div>
                      <select placeholder="Gender" [(ngModel)]="patient_gender"  class="form-control form-select">
                        <option selected="" disabled="" value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Other">Other</option>
                      </select>
                      
            </div>
            <div class="md-form col-6"> 
                      <div style="text-align: left;" class="ng-star-inserted"> Select Relation </div>
                      <select placeholder="Relation" [(ngModel)]="patient_relation"   class="form-control form-select">
                          <option value="" selected="" disabled="">Select Relation</option>
                          <option value="Relation">Self</option>
                          <option value="Relation">Relation</option>
                          <option value="Spouse">Spouse</option>
                          <option value="Daughter">Daughter</option>
                          <option value="Son">Son</option>
                          <option value="Father">Father</option>
                          <option value="Family">Family</option>
                          <option value="Mother">Mother</option>
                      </select>
                      
              </div>  
                         


          </div>
        </div>
        <div>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" *ngIf="addressSelected === false" mdbBtn color="" class="btn-primary" mdbWavesEffect
          (click)="chooseFromMaps()">
          {{util.translate('Confirm and Proceed')}}
        </a>
        <a type="button" *ngIf="addressSelected === true && editClicked ===false" mdbBtn color=""
          class="btn-primary" mdbWavesEffect (click)="addAddress()">
          {{util.translate('Add Address')}}
        </a>
        <a type="button" *ngIf="editClicked ===true" mdbBtn color="" class="btn-primary" mdbWavesEffect
          (click)="editMyAddress()">
          {{util.translate('Edit')}}
        </a>
        <a type="button" mdbBtn color="" outline="true" class="btn-primary" mdbWavesEffect data-dismiss="modal"
          (click)="addressFromMap.hide()"> {{util.translate('Cancel')}} </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>

<div mdbModal #changedPlace="mdbModal" class="modal fade center" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 99999;">
  <div class="modal-dialog modal-lg" role="document">
    <!--Content-->
    <div class="modal-content">

      <!--Body-->

      <div class="modal-body">
        <div class="headers">
          <p class="text">{{util.translate('Search Location')}}</p>
          <i class="fa fa-times-circle" aria-hidden="true" (click)="changedPlace.hide();addressFromMap.show()" ></i>  
           
        </div>
        <div class="md-form">
          <input id="cnum" style="height: auto !important;" [(ngModel)]="query" [placeholder]="util.translate('Search')"
            class="form-control" type="text" mdbInput (ngModelChange)="onSearchChange($event)">
        </div>
        <div class="addressList">
          <div class="select_addr" *ngFor="let item of autocompleteItems1" (click)="selectSearchResult1(item)">
            <i class="fa fa-map-marker" aria-hidden="true" style="color: gray; margin-right: 10px;"></i>  
            {{ item.description }}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div mdbModal #offersModal="mdbModal" class="modal fade center" id="frameModalTop" tabindex="-1" role="dialog"
  aria-labelledby="myModalLabel" aria-hidden="true" style="z-index: 99999;">
  <div class="modal-dialog modal-notify modal-success" role="document">
    <!--Content-->
    <div class="modal-content">
      <!--Header-->
      <div class="modal-header">
        <p class="heading lead" style="text-align: center;"> {{util.translate('Apply Coupon')}} {{this.cart.totalPrice}}</p>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="offersModal.hide()">
          <span aria-hidden="true" class="white-text">&times;</span>
        </button>
      </div>
      <div class="modal-body coupons">
        <div *ngFor="let item of offers" (click)="selectedOffers(item)" class="info">
          <p class="title">
            <span class="code">{{util.translate('CODE')}} : {{item.code}} </span><br>
            <span class="expire">{{getTime(item.exire)}}</span><br>
            <span class="desc">{{util.translate('Get')}} {{item.discount}} {{item.type}} <!-- {{util.translate('to')}} -->
              <!-- {{item.upto}}
              {{item.type}} --> {{util.translate('discount on minimum order')}}
              {{util.translate('of')}}

              <span *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.min}}</span>
              <span *ngIf="util.cside ==='right'"> {{item.min}} {{util.currecny}} </span>

            </span>
          </p>
        </div>
      </div>
      <!--Footer-->
      <div class="modal-footer justify-content-center">
        <a type="button" mdbBtn color="primary" outline="true" class="waves-light" mdbWavesEffect data-dismiss="modal"
          (click)="offersModal.hide()"> {{util.translate('Cancel')}} </a>
      </div>
    </div>
    <!--/.Content-->
  </div>
</div>
<div class="container">
  <h5 style="margin: 20px 0px;">{{util.translate('View cart & checkout')}}</h5>
  <div class="code_div" *ngIf="!cart.discount || !cart.coupon ">
    <label style="cursor: pointer;" (click)="offersModal.show()">
      <i class="fa fa-tag" aria-hidden="true"></i> 
      {{util.translate('Have a coupon?')}} <span> {{util.translate('Click here')}} </span> {{util.translate('to enter
      your code')}}
    </label>
  </div>

  <div *ngIf="cart.discount && cart.coupon" class="applied">
    <label style="cursor: pointer;">
      <i class="fa fa-tag" aria-hidden="true"></i>  
      {{util.translate('Applied coupon CODE')}} : {{cart.coupon.code}} {{util.translate('Get')}}
      {{cart.coupon.discount}} {{cart.coupon.type}} {{util.translate('to')}}
      {{cart.coupon.upto}}
      {{cart.coupon.type}} {{util.translate('discount on minimum order')}}
      {{util.translate('of')}}
      <span *ngIf="util.cside ==='left'"> {{util.currecny}} {{cart.coupon.min}} </span>
      <span *ngIf="util.cside ==='right'"> {{cart.coupon.min}} {{util.currecny}} </span>

    </label>
    <i class="fa fa-times-circle" (click)="removeOffer()" class="iconEnd" aria-hidden="true"></i>   
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="tabs">

        <div class="tab">
          <input type="radio" id="rd1" name="rd" value="rd1" [(ngModel)]="toggle" disabled="true">
          <label class="tab-label" for="rd1">{{util.translate('Patient Details')}}  
          </label>
          <div class="tab-content">
            <div *ngFor="let item of dummy" class="addrList">
              <p class="title">
                <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'70px'}"> </ngx-skeleton-loader>
              </p>
              <p class="fullAdr">
                <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'100%'}">
                </ngx-skeleton-loader>
              </p>
              <p class="fullAdr">
                <ngx-skeleton-loader appearance="line" [theme]="{  height: '20px',width:'80%'}">
                </ngx-skeleton-loader>
              </p>
            </div>
            <div *ngFor="let item of myaddress" class="addrList">
              <p class="title">{{item.name}} </p> 
              <p class="fullAdr"> {{item.title}}  - {{item.landmark +' '+item.house +' '+item.address +' '+ item.pincode}}
              </p>
              <div class="btns">
                <div class="select btn-primary-sm" (click)="selectAddress(item)">
                  {{util.translate('Select Patient')}}
                </div>
                <div class="edit btn-primary-sm" (click)="editAddress(item)">
                  {{util.translate('Edit')}}
                </div>
              </div>
            </div>
            <div class="btn btn-primary-sm" (click)="addNewAddress()">
              <i class="fa fa-plus" aria-hidden="true"></i>  
              {{util.translate('Add new patient')}}
            </div>
          </div>

        </div>
        <div class="deliveryAddress" *ngIf="cart && cart.deliveryAddress && cart.deliveryAddress.address">
          <p class="name"><span class="bolder"> {{cart.deliveryAddress.name}} {{cart.deliveryAddress.title}} </span>
            {{cart.deliveryAddress.landmark +' ' +cart.deliveryAddress.house +cart.deliveryAddress.address +' '+
            cart.deliveryAddress.pincode}}
          </p>
          <p class="changeBtn" mdbWavesEffect (click)="toggle='rd1';cart.deliveryAddress=null">
            {{util.translate('Change')}}</p>
        </div>
        <div class="tab">
          <input type="radio" id="rd2" name="rd" value="rd2" [(ngModel)]="toggle" disabled="true">
          <label class="tab-label" for="rd2">{{util.translate('Select Payment Method')}}</label>
          <div class="tab-content">

            <div class="paymentsTabs">
              <h2 class="header">{{util.translate('All Payment Options')}}</h2>
              <!-- <div #paypal></div> -->
              <div class="payPalBtn" *ngIf="cart.havePayment && cart.havePayPal">
                <ngx-paypal [config]="payPalConfig"></ngx-paypal>
              </div>
              <div class="radioBtns">
                
                 

                <div class="flexer" (click)="payMethod('cod')" *ngIf="cart.havePayment && cart.haveCOD">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='cod'">
                  <p class="payName"> {{util.translate('COD/Pay at time of collection')}} </p>
                  <img src="assets/imgs/cod.png" class="imgs" />
                </div>

                <div class="flexer" (click)="payMethod('stripe')" *ngIf="cart.havePayment && cart.haveStripe">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='stripe'">
                  <p class="payName"> {{util.translate('Pay with Stripe')}} </p>
                  <img src="assets/imgs/stripe.png" class="imgs" />
                </div>

                <div class="flexer" (click)="payMethod('razor')" *ngIf="cart.havePayment && cart.haveRazor">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='razor'">
                  <p class="payName"> {{util.translate('Pay with RazorPay')}} </p>
                  <img src="assets/imgs/razor.svg" class="imgs" />
                </div>

                <div class="flexer" (click)="payMethod('paytm')" *ngIf="cart.havePayment && cart.havePayTM">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='paytm'">
                  <p class="payName"> {{util.translate('Pay with PayTM')}} </p>
                  <img src="assets/imgs/paytmlogo.png" class="imgs" />
                </div>

                <div class="flexer" (click)="payMethod('instamojo')" *ngIf="cart.havePayment && cart.haveInstamojo">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='instamojo'">
                  <p class="payName"> {{util.translate('Pay with Instamojo')}} </p>
                  <img src="assets/imgs/instalogos.png" class="imgs" />
                </div>

                <div class="flexer" (click)="payMethod('paystacks')" *ngIf="cart.havePayment && cart.havePayStack">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='paystacks'">
                  <p class="payName"> {{util.translate('Pay with PayStacks')}} </p>
                  <img src="assets/imgs/paystack.png" class="imgs" />
                </div>

                <div class="flexer" (click)="payMethod('flutterPay')" *ngIf="cart.havePayment && cart.haveFlutterwave">
                  <img src="assets/check.png" alt="" class="checkMark" *ngIf="payMethods=='flutterPay'">
                  <p class="payName"> {{util.translate('Pay with Flutterwave')}} </p>
                  <img src="assets/imgs/flutter.png" class="imgs" />
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="col-lg-12">
      <div class="cart_box">
        <p class="title">{{util.translate('Lab Test Summary')}}</p>
        <div class="noData" *ngIf="!cart.cart?.length">
          <img src="assets/images/cart.png" alt="" class="noDataImg">
          <label>{{util.translate('Your Cart is Empty')}}</label>
          <label>{{util.translate('Please add your lab test')}} <a href="/labtest"> click here</a></label>
        </div>
        <div class="cart_items" *ngIf="cart.cart?.length">
          <div *ngFor="let item of cart.cart;let i = index">
            <div class="labtest-list" *ngIf="item.quantiy > 0 && !item.selectedItem?.length">
              <!-- <div class="col-2 cover_img"
                [ngStyle]="{'background-image':'url('+api.mediaURL+item.cover+'),url(assets/placeholder.jpg)'}"></div> -->
              <div class="">
                <label class="heading_lbl">
                  {{ (item.name.length>120)? (item.name | slice:0:120)+'..':(item.name) }}
                </label>
                <!-- <div class="Rating">
                  ({{item.rating}} {{util.translate('Ratings')}})
                </div> -->
                <!-- <label class="small_lbl">
                  {{ (item.name.length>130)? (item.name | slice:0:130)+'..':(item.name) }}
                </label> -->
                
              </div>
              <div class="">
                <div class="item-count">
                  <div class="cart_btn"> 
                    <label class="btns" mdbWavesEffect (click)="removeCartQ(i)"><i class="fa fa-trash-o text-danger"></i></label>
                    <!-- <label class="totls">{{item.quantiy}} </label>
                    <label class="btns" mdbWavesEffect (click)="addCartQ(i)">+</label> -->
                  </div>
                  <div class="price-align"> 
                      <label class="heading_lbl" *ngIf="util.cside ==='left'"> {{util.currecny}} {{item.test_rate | number : '.2-2'}}</label>
                      <label class="heading_lbl" *ngIf="util.cside ==='right'">{{item.test_rate | number : '.2-2'}} {{util.currecny}} </label>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="item.quantiy > 0 && item.selectedItem?.length">
              <div class="row" *ngFor="let subItems of item.selectedItem;let j = index">
                <div class="col-2 cover_img"
                  [ngStyle]="{'background-image':'url('+api.mediaURL+item.cover+'),url(assets/placeholder.jpg)'}"></div>
                <div class="col-6">
                  <label class="heading_lbl">{{item.name}}</label>
                  <p *ngFor="let addods of subItems.item" style="margin: 0px;font-size: 10px;color: black;">
                    - {{addods.name}}
                    <span style="color: black;" *ngIf="util.cside ==='left'"> {{util.currecny}} {{addods.value}}</span>
                    <span style="color: black;" *ngIf="util.cside ==='right'"> {{addods.value}} {{util.currecny}}
                    </span>
                  </p>
                </div>
                <div class="col-4">
                  <div class="cart_btn">
                    <label class="btns" mdbWavesEffect (click)="removeAddonCartQ(i,j)">-</label>
                    <label class="totls">{{item.selectedItem[j].total}} </label>
                    <label class="btns" mdbWavesEffect (click)="addAddonCartQ(i,j)">+</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="cart.cart?.length">
          <div class="cart_checkout">
            <div  class="subTotal_css">
                <div class="prices">
                  <span class="subTotal">
                    {{util.translate('Subtotal')}} :</span>
                  <span class="cost" *ngIf="util.cside ==='left'">
                    {{util.currecny}} {{cart.totalPrice}}
                  </span>
                  <span class="cost" *ngIf="util.cside ==='right'">
                    {{cart.totalPrice}} {{util.currecny}}
                  </span>
                </div>
                <div class="prices" *ngIf="cart.discount && cart.coupon">
                  <span class="subTotal">
                    {{util.translate('Discount')}} :</span>
                  <span class="cost" *ngIf="util.cside ==='left'">
                    - {{util.currecny}} {{cart.discount}}
                  </span>
                  <span class="cost" *ngIf="util.cside ==='right'">
                    - {{cart.discount}} {{util.currecny}}
                  </span>
                </div>
                <div class="prices">
                  <span class="subTotal">
                    {{util.translate('Taxes & charges')}} ({{cart.flatTax}} %) :</span>
                  <span class="cost" *ngIf="util.cside ==='left'">
                    {{util.currecny}} {{cart.orderTax}}
                  </span>
                  <span class="cost" *ngIf="util.cside ==='right'">
                    {{cart.orderTax}} {{util.currecny}}
                  </span>
                </div>
                <div class="prices">
                  <span class="subTotal">
                    {{util.translate('Collection  Charge')}} :</span>
                  <span class="cost" *ngIf="util.cside ==='left'">
                    {{util.currecny}} {{cart.deliveryPrice | number : '.2-2'}}
                  </span>
                  <span class="cost" *ngIf="util.cside ==='right'">
                    {{cart.deliveryPrice | number : '.2-2' }} {{util.currecny}}
                  </span>
                </div>
                <div class="prices">
                  <span class="grand">
                    {{util.translate('Grand Total')}} :</span>
                  <span class="cost" *ngIf="util.cside ==='left'">
                    {{util.currecny}} {{cart.grandTotal}}
                  </span>
                  <span class="cost" *ngIf="util.cside ==='right'">
                    {{cart.grandTotal}} {{util.currecny}}
                  </span>

                </div>
              </div>
            <textarea [placeholder]="util.translate('Add special instructions')" [(ngModel)]="orderNotes"
              cols="30" rows="5"></textarea>
            <div class="prices slotcss">
              <span class="grand">
                {{util.translate('Schedule Date Slot')}} :</span>
              <span class="cost" *ngIf="util.cside ==='left'">
                   <input type="date" [(ngModel)]="schedule_date" (ngModelChange)="SchDate($event)" min="{{sdate}}" class="form-control datepicker-input" >
              </span>
              <span class="cost" *ngIf="util.cside ==='right'">
                 <input type="date"  [(ngModel)]="schedule_date" (ngModelChange)="SchDate($event)" min="{{sdate}}" class="form-control" >
              </span>

            </div>
            
            <div class="prices">
              <span class="grand">
                {{util.translate('Schedule Time Slot')}} :
                </span>
              <span class="cost" *ngIf="util.cside ==='left'"> 
                    {{BookingForB}}
              </span>
            </div>
            <div class="prices"> 
              <span class="grand">  
                <div *ngIf="schedule_date===sdate">
                    <span class="cost" *ngFor="let TimeVal  of LabTestTiming;let j = index" >
                      <!-- *ngIf="this.TimeVal.slice(0, 2)> CTime" -->
                      <button *ngIf="this.TimeVal.slice(0, 2)> CTime" id={{this.j}} class=" btn" (click)="SchTime(this.TimeVal);ChangeColor(this.j)" [ngClass]="{'green' : toggle1, 'red': !toggle1}" >{{this.TimeVal}}</button>
                    </span>
                </div>
                <div *ngIf="schedule_date>sdate">
                    <span class="cost" *ngFor="let TimeVal  of LabTestTiming;let j = index" > 
                      <button id={{this.j}} class=" btn" (click)="SchTime(this.TimeVal);ChangeColor(this.j)" [ngClass]="{'green' : toggle1, 'red': !toggle1}" >{{this.TimeVal}}</button>
                    </span>
                </div>

                  </span> 

             </div>

             <div class="btnOrder">  
              <button class="btn btn-primary-sm" (click)="proceed()">{{util.translate('Place order')}}
                <span *ngIf="util.cside ==='left'">{{util.currecny}} {{cart.grandTotal}} </span>
                <span *ngIf="util.cside ==='right'"> {{cart.grandTotal}} {{util.currecny}} </span> 
              </button>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>
